.root {
  justify-content: space-evenly;
  display: flex;
  flex-wrap: wrap;
}
.field {
  margin: 0.5em !important;
  min-width: 25% !important;
  flex-grow: 1 !important;
  flex-basis: 12.2em !important;
}
.avatar {
  margin: auto !important;
  margin-bottom: 1.5em !important;
}
